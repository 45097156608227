<template>
  <InsuranceSkladUsaEditingSection
          :ISU="ISU"
          :createData="createData"
          @save="save"
  />
</template>

<script>
  import {InsuranceSkladUsa} from "../../models/InsuranceSkladUsa";
  import {InsuranceSkladUsaMixin} from "../../../../../mixins/insuranceSkladUsaMixins/insuranceSkladUsaMixin";
  import InsuranceSkladUsaEditingSection from "./InsuranceSkladUsaEditingSection/InsuranceSkladUsaEditingSection";

  export default {
    name: "InsuranceSkladUsaCreation",

    components:{
      InsuranceSkladUsaEditingSection,
    },

    mixins: [InsuranceSkladUsaMixin],

    data() {
      return {
        ISU: new InsuranceSkladUsa(),

        createData: null,
      }
    },

    mounted() {
      this.initInsuranceSkladUsaEdit()
    },

    methods: {
      initInsuranceSkladUsaEdit() {

        this.$store.dispatch('getInsuranceSkladUsaCreate').then(response => {
          this.createData = this.getRespData(response)

          this.ISU.setId(this.$route.params.id)

          this.$store.dispatch('getInsuranceSkladUsa', this.ISU.getId()).then((response) => {
            let item = this.getRespData(response)

            this.ISU.setItem(item)
          }).catch(error => this.createErrorLog(error))
        })

      },

    }

  }
</script>

<style scoped>

</style>
